<template>
<div>
  <div class="wenjuan_list" v-loading="loading">
    <div class="info">
      <h3>{{ info.title }}</h3>
      <p class="blue"><span class="quesLink">问卷链接：</span>{{ info.linkurl}}</p>
      <p class="other">
        <span>编号：{{ info.id }}  </span>
        <span>创建：{{ info.create_time }}</span>
        <span>开始：{{ info.start_time }}</span>
        <span>截止：{{ info.end_time}}</span>
        <span v-if="info.status==2">状态：<b class="blue">调研进行中</b> </span>
        <span v-else-if="info.status==3">状态：<b class="green">调研结束</b> </span>
        <span v-else-if="info.status==1">状态：<b class="red">未启动</b> </span>
        <span v-else-if="info.status==0">状态：<b class="red">草稿</b> </span>
        <span>答卷：<b class="blue">{{ info.num }}</b> 份</span>
      </p>
    </div>
    <div class="main_table">
      <div class="filtrate clearfix">
        <div class="filtrate_item">
          <span class="item_name">企业名称</span>
          <el-input v-model="companyName" placeholder="输入企业名称" style="width: 240px;"></el-input>
        </div>
<!--        <div class="filtrate_item">-->
<!--          <span class="item_name">是否星标</span>-->
<!--          <el-select v-model="star" placeholder="请选择" style="width: 140px;">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
        <a class="btn" href="javascript:;" @click="searchBtn">查询</a>
      </div>
      <el-table
          ref="multipleTable"
          :data="tableData"
          :border="true"
          style="width: 100%"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="80"
            align="center">
        </el-table-column>
<!--        <el-table-column-->
<!--            label="星标"-->
<!--            width="80"-->
<!--            align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <img v-if="scope.row.star" class="star"-->
<!--                 src="@/assets/answerSheet/star_orange_icon@2x.png" alt=""/>-->
<!--            <img v-else class="star"-->
<!--                 src="@/assets/answerSheet/star_grey_icon@2x.png" alt=""/>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            prop="company"
            label="企业名称"
            min-width="100%">
        </el-table-column>
        <el-table-column
            prop="area_name"
            label="所在地区">
        </el-table-column>
        <el-table-column
            prop="organize_name"
            label="所属网格">
        </el-table-column>
        <el-table-column
            prop="full_name"
            label="商企经理">
        </el-table-column>
        <el-table-column
            prop="integrity"
            label="完整度"
            width="120"
            align="center"
            class-name="color-red">
        </el-table-column>
        <el-table-column
            prop="committime"
            label="提交时间"
            width="160">
        </el-table-column>
        <el-table-column
            label="操作"
            width="170">
          <template slot-scope="scope">
            <a href="JavaScript:;" class="handle" @click="openLook(scope.row.id)">查看答卷</a>
            <span class="handle" @click="downloadAnswer(scope.row.id)">下载答卷</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="operate">
        <div class="handle">
          <div class="all-select">
            <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
          </div>
          <div class="button" @click="toggleSelection()">全选</div>
          <div class="button" @click="downloadAllAnswers">下载答卷</div>
        </div>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout="prev, pager, next, sizes"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>

  <!-- s-弹窗 -->
</div>
</template>

<script>
import {datalist, statistics_show} from "../../request/questionApi";

export default {
  name: "sheet",
  data(){
    return{
      loading: true,
      info:{},
      total:null,
      star:null,
      companyName:null,
      limit:10,
      page:1,
      input: '', //企业名称
      options: [{
        value: 0, //id
        label: '否'
      }, {
        value: 1,
        label: '是'
      }],
      titleOptions:[
        {
          value: '选项1',
          label: '题目一'
        }, {
          value: '选项2',
          label: '题目二'
        }, {
          value: '选项3',
          label: '题目三'
        }, {
          value: '选项4',
          label: '题目四'
        }, {
          value: '选项5',
          label: '题目五'
        }
      ],
      value: '', //是否星标
      value1: '', //题目标题
      status:2,
      tableData: [ //表格数据
      ],
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 全选
      multipleSelection: [], // 表格选中列表
      currentPage: 1
    }
  },
  mounted() {
    this.initData();
    let data = {
      queid:this.$route.query.id,
      star:this.star,
      company:this.companyName,
    }
    this.$api.datalist(data).then(res=>{
      this.total = res.count
    })
  },
  methods:{
    initData(){
      let data = {
        queid:this.$route.query.id,
        star:this.star,
        company:this.companyName,
        limit:this.limit,
        page:this.page
      }
      this.$api.datalist(data).then(res=>{
        this.tableData = res.data.list
      })
      this.$api.statistics_show({qid:this.$route.query.id}).then(res=>{
        this.info = res.data
        this.loading = false;
        console.log(res)
      })
    },
    openLook(e) { //查看答卷
      let pathInfo = this.$router.resolve({path: '/suervey', query: {id: e}});
      window.open(pathInfo.href, '_blank');
    },
    downloadAnswer(id) { //下载答卷
      this.$api.questionnaire_download_question({id: id}).then(res => {
        this.fileDownload(res.data.url)
      })
    },
    downloadAllAnswers() { //下载全部答卷
      const idArray = [];
      this.multipleSelection.forEach(item => {
        idArray.push(item.id);
      });
      const params = {
        ids: idArray
      }
      console.log(idArray, 'params===========')
      this.$api.contact_list(params).then(res => {
        this.fileDownload(res.data.url)
      })
    },
    searchBtn(){ //查询
      let data = {
        queid:this.$route.query.id,
        star:this.star,
        company:this.companyName,
        limit:this.limit,
        page:this.page
      }
      this.$api.datalist(data).then(res=>{
        this.tableData = res.data.list
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;
      console.log(val)
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleStarChange(index) {
      this.tableData[index].star = !this.tableData[index].star;
    }, // 单个星标点击
    handleView(index,row) {
      // this.$router.push({path:'/suervey',query:{index:index}})
    },
    handleDownload() {

    },
    handleSizeChange(val) {
      let data = {
        page: 1,
        limit: val,
        queid:this.$route.query.id
      }
      this.$api.datalist(data).then(res=>{
        this.tableData = res.data.list
      })
    },
    handleCurrentChange(val) {
      let data = {
        page: val,
        limit: this.limit,
        queid:this.$route.query.id
      }
      this.$api.datalist(data).then(res=>{
        this.tableData = res.data.list
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input__icon {
  line-height: 34px;
}
.wenjuan_list {
  background: #fff;
  margin: 18px;
  padding-bottom: 20px;
  .info {
    padding: 19px 20px 18px;
    .quesLink{
      color: #6C7880;
    }
    h3 {
      position: relative;
      line-height: 36px;
      font-size: 20px;
    }
    .dload {
      position: absolute;
      right: 0;
      top: 1px;
      line-height: 34px;
      height: 34px;
      background: #04cd83;
      padding: 0 14px;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      i {
        display: inline-block;
        width: 18px;
        height: 15px;
        background: url(~@/assets/statistics/analysis_icon.png) 0 -62px no-repeat;
        vertical-align: middle;
      }
      &:hover {
        background: #05c07b;
      }
    }
    p {
      line-height: 24px;
    }
    .other {
      line-height: 32px;
      color: #6C7880;
      span {
        margin-right: 40px;
      }
      b {
        font-weight: normal;
      }
    }
    .blue {
      color: #1190f3;
    }
    .green {
      color: #04cd83;
    }
    .red{
      color: #FD5451;
    }
  }
}
.main_table {
  padding: 0 20px 20px;
  .filtrate {
    margin-bottom: 20px;
    display: flex;
    .down_select {
      float: left;
      margin-right: 10px;
    }
    .btn {
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      background-color: #3984F5;
      &:hover {
        background-color: #0073cc;
      }
    }
    .filtrate_item{
      ::v-deep .el-input .el-input__inner{
        height: 34px;
        line-height: 34px;
      }
      .item_name{
        color: #21333F;
        font-size: 12px;
        margin-right: 12px;
      }
      margin-right: 40px;
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .handle {
      display: flex;
      .all-select {
        margin-right: 16px;
        padding-left: 34px;
        line-height: 32px;
      }
      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;
        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
  table {
    .star {
      margin-bottom: 2px;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
    .handle {
      margin-right: 20px;
      color: #3984F5;
      cursor: pointer;
    }
  }
}
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;
  &::before,
  &::after {
    background-color: #DCE5EC;
  }
  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }
  .cell {
    line-height: 24px;
  }
  thead {
    color: #242D33;
  }
  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }
  th {
    font-weight: 400;
  }
  .el-table-column--selection .cell {
    padding-right: 10px;
  }
  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }
  td.el-table__cell {
    padding: 13px 0;
  }
  td.color-red {
    color: #FD5451;
  }
}

::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;
  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }
  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }
  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }
  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }
  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-select .el-input {
    margin-right: 0;
    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      &:hover {
        border-color: #3984F5;
      }
    }
  }
  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}
</style>